import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Hero } from '@components';
import { Offers } from '../components/PageTemplates/AboutPage';

const OfferPage = ({ data }) => {
  const { frontmatter = {} } = data.markdownRemark;

  const {
    hero,
    seo,
    offers,
  } = frontmatter;

  return (
    <>
      <Hero {...hero} seo={seo} />
      <Offers offers={offers} />
    </>
  );
};

OfferPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object.isRequired,
  }),
  location: PropTypes.object.isRequired,
};

export default OfferPage;

export const offerPageQuery = graphql`
  query OfferPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        hero {
          title
          markdown
        }
        offers {
          title {
            text
            color
          }
          markdown
          items {
            title
            markdown
            id
            image {
              alt
              src {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
            }
          }
        }
        seo {
          metaTitle
          metaDescription
        }
      }
    }
  }
`;
